<template>
    <div class="tenders-item">
     <div class="tenders-item__header">
      <p>
       {{ tender.name }}
      </p>
      </div>
      <div class="tenders-item__body">
       <table>
        <tr>
         <td>Наименование оборудования:</td>
         <td>{{ tender.equipmentName }}</td>
        </tr>
        <tr>
         <td>Срок подачи документов:</td>
         <td>{{ tender.date | moment('DD.MM.YYYY') }}</td>
        </tr>
       </table>
        <button class="button-download" >
          <img src="@/assets/img/download_blue.svg" />
          <a :href="`${API_URL}${tender.document.url}`" download class="active">Скачать ТЗ</a>
        </button>
    </div>
   </div>
</template>
<script>
import '@/assets/sass/tenders-item.sass';
import config from '@/config';

export default {
  name: 'TendersItem',
  props: {
    tender: {
      default: () => {},
      type: Object,
    },
  },
  computed: {
    API_URL() {
      return config.API_URL;
    },
  },
};
</script>
