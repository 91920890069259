<template>
  <div class="modal" @click="closeModal">
    <form class="modal-card" @submit.prevent="submit">
      <div class="modal-header">
        <img class="logo" src="@/assets/img/Logo_wh.svg" />
        <label class="modal-header__theme modal-header__theme--disabled">
          <div class="select select-175">Участие в тендере</div>
        </label>
        <div class="modal-header__phone">
          <p>Мы с радостью ответим на любые Ваши вопросы по телефону:</p>
          <a :href="`tel: ${contacts.receptionNumber}`">{{ contacts.receptionNumber }}</a>
        </div>
        <button class="modal-close" @click="$emit('close')" type="button"></button>
      </div>
      <div class="modal-body">
        <input
          type="text"
          v-model="form.companyName"
          required
          placeholder="Введите название компании"
        />
        <input
          type="tel"
          v-model="form.phone"
          required
          class="modal-input__phone"
          placeholder="Введите телефон"
        />
        <input
          type="text"
          v-model="form.email"
          required
          placeholder="Введите Email"
        />
        <button class="upload" type="button">
          <label>
            <input id="document-input" type="file" @input="onUpload">
            <img src="@/assets/img/clip-gray.svg" /> {{ buttonText }}
          </label>
        </button>
        <div class="input-checkbox">
          <label :class="form.agree ? 'checked' : ''">
            <input type="checkbox" v-model="form.agree" />
            Настоящим я подтверждаю достоверность введенных мною сведений и даю
            согласие на обработку моих персональных данных в соответствии c
            <a :href="`${API_URL}${contacts.politics.url}`" v-if="contacts.politics" download>
              Политикой Конфиденциальности
            </a>
          </label>
          <button type="submit">
            Отправить
            <img src="@/assets/img/Arrow_top.svg" />
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Inputmask from 'inputmask';
import { mapState } from 'vuex';
import config from '@/config';

export default {
  name: 'HotLineModal',
  data() {
    return {
      file: null,
      fileUploaded: false,
      buttonText: 'Прикрепить файл',
      form: {
        name: '',
        phone: '',
        email: '',
        registrationAddress: '',
        address: '',
        contractNumber: '',
        contractDate: '',
        description: '',
        companyName: '',
        theme: 'Участие в тендере',
        file: '',
        agree: true,
      },
    };
  },
  computed: {
    ...mapState({
      contacts: (state) => state.contacts.contacts,
    }),
    API_URL() {
      return config.API_URL;
    },
  },
  methods: {
    submit(e) {
      e.preventDefault();
      this.$store.dispatch('uploadFile', this.file).then((file) => {
        this.form.file = file;
        this.$store.dispatch('sendRequest', this.form).then(this.$emit('close'));
      });
    },
    closeModal(event) {
      if (!event.target.classList.contains('modal')) return;
      this.$emit('close');
    },
    keydown(event) {
      if (event.keyCode !== 27) return;
      this.$emit('close');
    },
    onUpload(event) {
      this.fileUploaded = true;
      const file = event.target.files[0];
      this.file = file;
      this.buttonText = file.name;
    },
  },
  mounted() {
    const phone = document.querySelector('.modal-input__phone');
    Inputmask('+7 (999) 999-99-99').mask(phone);
    document.addEventListener('keydown', this.keydown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keydown);
  },
};
</script>
