<template>
  <div class="tenders">
    <Loader v-if="isLoading" />

    <div class="container" v-if="!isLoading">
      <div class="h4">{{ tendersPage.name }}</div>
      <p class="text">
       {{ tendersPage.title }}
      </p>
      <p class="textabove">
        {{ tendersPage.description }}
      </p>
      <div class="buttongroup">
        <a :href="`${API_URL}${tendersPage.document.url}`" download class="active button">
          <img src="@/assets/img/download_white.svg" />Скачать договор поставки</a>
        <button class="button" @click="openModal">Заявка на участие в тендере</button>
      </div>
      <div class="selector-overlay">
        <div class="selector">
          <a v-for="category in tendersCategories" :key="category.id"
            :class="{active: category.id === activeCategory}"
            @click="activeCategory=category.id">
            {{ category.name }}
          </a>
        </div>
      </div>
     <div class="tenders-list">
        <TendersItem v-for="item in getFilteredTendersList(activeCategory)" :key="item.id" :tender="item"/>
      </div>
    </div>

    <transition name="fade">
      <TendersModal v-if="isModalOpen" @close="closeModal" />
    </transition>
  </div>
</template>

<script>
import '@/assets/sass/tenders.sass';
import Loader from '@/components/Loader.vue';
import TendersItem from '@/components/Tenders/TendersItem.vue';
import { mapState, mapGetters } from 'vuex';
import config from '@/config';
import TendersModal from '@/components/TendersModal.vue';

export default {
  name: 'Tenders',
  components: { Loader, TendersItem, TendersModal },
  data() {
    return {
      isLoading: true,
      activeCategory: 1,
      isModalOpen: false,
    };
  },
  computed: {
    ...mapState({
      tendersPage: (state) => state.tenders.tendersPage,
      tendersCategories: (state) => state.tenders.tendersCategories,
    }),
    ...mapGetters([
      'getFilteredTendersList',
    ]),
    API_URL() {
      return config.API_URL;
    },
  },
  methods: {
    loadPageData() {
      this.$store.dispatch('getTendersPage').then(this.loadCategories);
    },
    loadCategories() {
      this.$store.dispatch('getTendersCategories').then(this.loadData);
    },
    loadData() {
      this.$store.dispatch('getTendersList').then(() => {
        this.isLoading = false;
      });
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
  mounted() {
    this.isLoading = true;
    this.loadPageData();
    this.$emit('setLastCrumb', 'Тендеры');
  },
};
</script>
